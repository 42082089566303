"use client"

import { HomeCard } from "@/components/ui/card/HomeCard"
import { ROUTES } from "@/interfaces/constants/routes"
import { useHomeProfileStore } from "@/stores/ui/useHomeProfileStore"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Grow, Stack } from "@mui/material"
import { useRef, useState } from "react"
import { InView } from "react-intersection-observer"

const APPLICANT_CARDS = [
  {
    title: "Buscando uma vaga?",
    imageSrc: getAssetsPath() + "/img/ilustracao-vagas.svg",
    imageAlt: "ilustração de uma pessoa lendo documentos no computador",
    accessLinkUrl: ROUTES.HOME_BUSCAVAGAS,
    additionalInfo: [
      "Encontre diversas oportunidades",
      "Cadastre seu currículo",
      "Aumente suas chances com a análise de perfil",
    ],
  },
  {
    title: "Em busca de capacitação?",
    imageSrc: getAssetsPath() + "/img/ilustracao-capacitacao.svg",
    imageAlt:
      "ilustração de uma pessoa segurando um celular a procura de cursos de qualificação",
    accessLinkUrl: "#",
    additionalInfo: [
      "Encontre cursos disponibilizados pelas instituições parceiras",
      "Descubra em qual área seu perfil se destaca mais",
      "Alcance seus objetivos através da qualificação para o mercado de trabalho",
    ],
  },
  {
    title: "Quer o seu voucher qualificação?",
    imageSrc: getAssetsPath() + "/img/ilustracao-voucher.svg",
    imageAlt: "ilustração de uma pessoa segurando um voucher",
    accessLinkUrl: "#",
    additionalInfo: [
      "Vouchers exclusivos para aprimoramento profissional",
      "Cadastre seu interesse para receber um voucher",
      "Gerencie sua participação no programa",
    ],
  },
]

const EMPLOYER_CARDS = [
  {
    title: "Buscando por candidatos?",
    imageSrc: getAssetsPath() + "/img/ilustracao-vagas.svg",
    imageAlt: "ilustração de uma pessoa lendo documentos no computador",
    accessLinkUrl: "#",
    additionalInfo: [
      "Busque ativamente o candidato ideal",
      "Acesse o currículo completo do candidato",
      "Filtre por cidade ou competência necessária para a vaga",
    ],
  },
  {
    title: "Quer qualificar sua equipe?",
    imageSrc: getAssetsPath() + "/img/ilustracao-capacitacao.svg",
    imageAlt:
      "ilustração de uma pessoa segurando um celular a procura de cursos de qualificação",
    accessLinkUrl: "#",
    additionalInfo: [
      "Explore cursos disponíveis de instituições parceiras",
      "Indique pessoas ou empresas que possam se beneficiar dos cursos da plataforma",
      "Sugira novos cursos para atender às necessidades da sua equipe",
    ],
  },
  {
    title: "Quer ofertar uma vaga?",
    imageSrc: getAssetsPath() + "/img/ilustracao-anuncie-vaga.svg",
    imageAlt: "ilustração de uma pessoa segurando um documento um megafone",
    accessLinkUrl: "#",
    additionalInfo: [
      "Cadastre sua vaga e a disponibilize na FUNTRAB",
      "Ajuste o perfil mais adequado para a função desejada",
      "Gerencie os candidatos que manifestaram interesse",
      "Tenha sua vaga publicada no buscador do Google",
    ],
  },
]

export default function CardsSection() {
  const [isCardsLoaded, setIsCardsLoaded] = useState(false)
  const { isApplicant } = useHomeProfileStore()

  const cardsRef = useRef(APPLICANT_CARDS)
  cardsRef.current = isApplicant() ? APPLICANT_CARDS : EMPLOYER_CARDS

  return (
    <section>
      <InView
        as="section"
        onChange={(inView) => setIsCardsLoaded(inView)}
        threshold={0.2}
        triggerOnce
      >
        <Stack
          id={"home-nav"}
          sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: { xs: "column", md: "row" },
            gap: 5,
            my: 5,
          }}
        >
          {cardsRef.current.map((card, index) => (
            <Grow
              key={index}
              in={isCardsLoaded}
              timeout={index === 0 ? "auto" : { enter: 300 * index }}
            >
              <div style={{ width: "100%" }}>
                <HomeCard key={index} {...card} />
              </div>
            </Grow>
          ))}
        </Stack>
      </InView>
    </section>
  )
}
