"use client"

import LoginButton from "@/components/ui/button/LoginButton"
import { StepCompanyCard } from "@/components/ui/card/company-tutorial-steps/StepCompanyCard"
import { StepCompanyDescription } from "@/components/ui/card/company-tutorial-steps/StepCompanyDescription"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined"
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined"
import { Grid, Grow, Link, Typography } from "@mui/material"
import { CardMedia } from "@mui/material"
import Box from "@mui/material/Box"
import { useState } from "react"
import { InView } from "react-intersection-observer"

export default function CompanyStoreTutorial() {
  const [isTutorialLoaded, setIsTutorialLoaded] = useState(false)

  return (
    <section id="company-tutorial">
      <InView
        as="section"
        onChange={(inView) => {
          setIsTutorialLoaded(inView)
        }}
        threshold={0}
        triggerOnce
      >
        <Grow key={undefined} in={isTutorialLoaded} timeout={1000}>
          <Grid container my={"80px"} alignItems={"center"}>
            <Grid item md={8} xs={12}>
              <StepCompanyDescription
                title={"Cadastre sua empresa de forma simples"}
                image={"/img/logo-govbr.svg"}
                content={
                  <>
                    Para cadastrar empresa também é necessário ter conta{" "}
                    <strong>gov.br?</strong> Sim! Assim como outros serviços oficiais do
                    Governo, o MS Qualifica Digital possui login unificado pelo gov.br.
                    Mais praticidade e segurança para você
                  </>
                }
              />
              <Grid container>
                <StepCompanyCard
                  icon={
                    <PersonAddOutlinedIcon sx={{ color: "#114E99", fontSize: "40px" }} />
                  }
                  title={"Entre usando sua conta gov.br"}
                  content={
                    <>
                      <Typography>
                        Basta informar os dados que você usa para logar com sua conta
                        gov.br.
                      </Typography>
                      <LoginButton buttonsSx={{ margin: 2 }} />
                    </>
                  }
                />
                <StepCompanyCard
                  icon={
                    <WorkOutlineOutlinedIcon
                      sx={{ color: "#114E99", fontSize: "40px" }}
                    />
                  }
                  title={"Acesse o painel trabalhador"}
                  content={
                    <>
                      Ele é o painel vinculado ao seu CPF. Nele, você encontrará o acesso
                      ao formulário de cadastro de empresas.
                    </>
                  }
                />
                <StepCompanyCard
                  icon={
                    <AddCircleOutlineOutlinedIcon
                      sx={{ color: "#114E99", fontSize: "40px" }}
                    />
                  }
                  title={"Cadastre uma ou várias empresas"}
                  content={
                    <>
                      Preencha os dados da empresa a ser cadastrada e pronto, você será
                      redirecionado ao painel de empresa.
                    </>
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={8} md={4} sx={{ margin: "auto" }}>
              <Box alignContent={"center"}>
                <CardMedia
                  sx={{ borderRadius: "50px", padding: "10px", clipPath: "circle(90%)" }}
                  component="video"
                  src={`${getAssetsPath()}/mp4/msq-preview.mp4`}
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  playsInline={true}
                />
              </Box>
            </Grid>
          </Grid>
        </Grow>
      </InView>
    </section>
  )
}
