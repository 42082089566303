"use client"

import { Block } from "@mui/icons-material"
import { Box, Grid, Stack, Typography } from "@mui/material"
import { ReactNode } from "react"

interface StepCompanyCardProps {
  icon: ReactNode
  title: string
  content: ReactNode
}

export function StepCompanyCard({ icon, title, content }: StepCompanyCardProps) {
  return (
    <Grid
      item
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      md={4}
      xs={12}
      padding={2}
      mt={5}
    >
      <Stack
        sx={{
          border: "1px solid #004F9F26",
          borderRadius: "15px",
          padding: 1,
          height: "100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100px" }}
        >
          {icon}
        </Box>

        <Typography
          mb={2}
          color={"#114E99"}
          variant="h5"
          fontWeight={400}
          textAlign={"center"}
          sx={{ minHeight: "60px", flexGrow: 1 }}
        >
          {title}
        </Typography>

        <Stack sx={{ minHeight: "120px", flexGrow: 1, textAlign: "center" }}>
          {content}
        </Stack>
      </Stack>
    </Grid>
  )
}
