"use client"

import { AccordionPanel } from "@/components/ui/accordions/AccordionPanel"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Grid, Grow, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Image from "next/image"
import * as React from "react"
import { useState } from "react"
import { InView } from "react-intersection-observer"

export default function FrequentlyQuestions() {
  const [isFrequentlyQuestionsLoaded, setIsFrequentlyQuestionsLoaded] = useState(false)

  return (
    <section>
      <InView
        as="section"
        onChange={(inView) => {
          setIsFrequentlyQuestionsLoaded(inView)
        }}
        threshold={0}
        triggerOnce
      >
        <Grow key={undefined} in={isFrequentlyQuestionsLoaded} timeout={1000}>
          <Grid container my={"80px"} alignItems={"stretch"} id={"company-tutorial"}>
            <Grid
              item
              xs={12}
              sm={8}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <Typography
                mb={3}
                variant="h6"
                textAlign={"center"}
                sx={{
                  display: {
                    md: "none",
                    color: "#2555AB",
                  },
                }}
              >
                Perguntas Frequentes
              </Typography>

              <Box
                sx={{
                  position: "relative",
                  height: "100%",
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  alignItems: "stretch",
                }}
              >
                <Image
                  src={`${getAssetsPath()}/img/blue-background-arrow.svg`}
                  alt="Logo"
                  width={0}
                  height={0}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
                <Typography
                  variant="h4"
                  sx={{
                    position: "absolute",
                    top: "15px",
                    left: "90px",
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  Perguntas frequentes
                </Typography>
              </Box>

              {/* <Box
                sx={{
                  height: "100%",
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  alignItems: "stretch",
                }}
              >
                <Image
                  src={`${getAssetsPath()}/img/blue-background-arrow.svg`}
                  alt="Logo"
                  width={0}
                  height={0}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
              </Box> */}
            </Grid>

            <Grid sx={{ paddingX: "15px" }} item md={9} xs={12}>
              <Box mb={1}>
                <AccordionPanel
                  id="1"
                  title="Como aumentar minhas chances de ser selecionado?"
                  defaultExpanded
                  strongTitle
                >
                  Mantenha seus dados cadastrais sempre atualizados para que empregadores
                  consigam entrar em contato com você. Para se destacar entre os outros
                  candidatos, busque uma formação na sua área de interesse na aba
                  “Cursos”.
                </AccordionPanel>
              </Box>

              <Box mb={1}>
                <AccordionPanel
                  id="2"
                  title="O MS Qualifica Digital é da FUNTRAB?"
                  defaultExpanded
                  strongTitle
                >
                  O MS Qualifica Digital, assim como a FUNTRAB, é uma iniciativa do
                  Governo do Estado de Mato Grosso do Sul. São plataformas diferentes que
                  compartilham a mesma base de dados, permitindo que vagas de emprego,
                  currículos e cursos cadastrados no MS Qualifica Digital estejam
                  disponíveis para usuários da FUNTRAB e vice-versa.
                </AccordionPanel>
              </Box>

              <Box mb={1}>
                <AccordionPanel
                  id="3"
                  title="Porque tenho que acessar com minha conta gov.br?"
                  defaultExpanded
                  strongTitle
                >
                  No MS Qualifica Digital, tanto trabalhadores quanto empresas utilizam o
                  login gov.br para garantir mais segurança e simplicidade no acesso. Esse
                  é o mesmo sistema usado em diversos serviços oficiais do governo,
                  garantindo que suas informações estejam sempre protegidas. Com uma única
                  conta, você acessa todos os serviços disponíveis, seja para gerenciar
                  seu perfil de trabalhador ou cadastrar sua empresa, de forma prática e
                  segura.
                </AccordionPanel>
              </Box>
            </Grid>
          </Grid>
        </Grow>
      </InView>
    </section>
  )
}
